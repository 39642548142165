class RecaptchaWrapper {
  isScriptLoaded = false;

  notify() {
    this.isScriptLoaded = true;
  }

  render(ele, options, cb) {
    cb(window.grecaptcha.render(ele, options));
  }

  reset(widgetId) {
    if (typeof widgetId === 'undefined') {
      return;
    }

    this.assertLoaded();
    window.grecaptcha.reset(widgetId);
  }

  execute(widgetId) {
    if (typeof widgetId === 'undefined') {
      return;
    }

    this.assertLoaded();
    window.grecaptcha.execute(widgetId);
  }

  checkRecaptchaLoad() {
    if (Object.prototype.hasOwnProperty.call(window, 'grecaptcha') &&
    Object.prototype.hasOwnProperty.call(window.grecaptcha, 'render')) {
      this.notify();
    }
  }

  assertLoaded() {
    if (!this.isScriptLoaded) {
      throw new Error('ReCAPTCHA has not been loaded');
    }
  }
}

const recaptchaWrapper = new RecaptchaWrapper();

if (process.client) {
  if (typeof window !== 'undefined') {
    window.vueRecaptchaApiLoaded = () => {
      recaptchaWrapper.notify();
    };
  }
}

export { recaptchaWrapper };
