<template>
  <ClientOnly>
    <div>
      <slot />
      <div ref="root" />
    </div>
  </ClientOnly>
</template>
<script lang="ts" setup>
// Для использования необходимо добавить код ниже на страницу в которой компонент используется(см. пример на marketplace/pages/pokupatelyam.vue)
// useHead({
//   script: [
//     { src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, defer: true },
//   ],
// });

import { NuxtProcessWrappers } from '../utils/nuxtProcessWrappers.util';
import { recaptchaWrapper } from '../utils/recaptcha.util';
import { EGrecaptchaEnabledFlags } from '../enums/grecaptchaEnabledFlags.enum';

const config = useRuntimeConfig(); // eslint-disable-line
const props = withDefaults(
  defineProps<{
    // Ключ сайта
    sitekey?: string;
    // Тема
    theme?: string;
    // Значение бейджа
    badge?: string;
    // Размер виджета капчи
    size?: string;
    // tabIndex для виджета
    tabindex?: string;
    // Следует ли загружать скрипт капчи
    loadRecaptchaScript?: boolean;
    // id скрипта
    recaptchaScriptId?: string;
    // Хост капчи
    recaptchaHost?: string;
    // язык
    language?: string;
  }>(),
  {
    loadRecaptchaScript: true,
  },
);

const emits = defineEmits<{
  (e: 'onRender', value: string);
  (e: 'onVerify', value: string);
  (e: 'onExpired');
  (e: 'onError');
}>();

const root = ref();
const widgetId = ref();
const isRecaptchaEnabled = computed(() => config.public?.grecaptchaEnabled === EGrecaptchaEnabledFlags.Enabled);

function emitVerify(response: string) {
  emits('onVerify', response);
}

function emitExpired() {
  emits('onExpired');
}

function emitError() {
  emits('onError');
}

function reset() {
  NuxtProcessWrappers.runOnClient(() => {
    recaptchaWrapper.reset(widgetId.value);
  }, null);
}

function execute() {
  NuxtProcessWrappers.runOnClient(() => {
    recaptchaWrapper.execute(widgetId.value);
  }, null);
}

function render() {
  NuxtProcessWrappers.runOnClient(() => {
    if (!isRecaptchaEnabled.value) {
      return;
    }

    recaptchaWrapper.checkRecaptchaLoad();
    const options = {
      ...props,
      sitekey: config?.public?.grecaptchaSitekey ?? props.sitekey,
      callback: emitVerify,
      'expired-callback': emitExpired,
      'error-callback': emitError,
    };

    recaptchaWrapper.render(root.value, options, (id) => {
      widgetId.value = id;
      emits('onRender', id);
    });
  }, null);
}

onMounted(() => {
  nextTick(() => {
    render();
  });
});

defineExpose({
  root,
  widgetId,
  reset,
  execute,
});
</script>
